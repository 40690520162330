<template>
  <div class="fullheight ml-top">
    <b-container class="main fullheight" fluid>
      <b-row class="bg-white fullheight">
        <b-col class="ml-sidebar fullheight" md="3">
          <div class="ml-content-nav fixed ml-border-right">
            <h2>News</h2>
            <!--<li>
              <span>
                <a href="#microlibrary">〔カテゴリー〕</a>
              </span>
            </li>-->
          </div>
        </b-col>
        <b-col md="9">
          <b-row>
            <b-col md="10 ml-content">
              <transition-group class="columns is-gapless is-multiline" name="fade" tag="section">
                <article
                  class="post column is-3"
                  v-for="post in posts"
                  v-bind:key="post.title.rendered"
                >
                  <h2 class="title" v-html="post.title.rendered"></h2>
                  <div
                    class="date"
                  >{{ dateToFormatString(new Date(post.date), '%YYYY%年%MM%月%DD%日（%w%）') }}</div>
                  <div class="post_content" v-html="post.content.rendered"></div>
                </article>
              </transition-group>
              <!--
            <button
            class="button is-primary"
            :class="[{
        'is-loading': loading,
        'is-desabled': disabled
      }]"
            :disabled="disabled"
            @click="load"
              >読み込み</button>-->
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style  lang="scss" scoped>
a {
  display: block;
  border-left: 4px solid #fff;
  padding-left: 10px;
  &:link,
  &:visited,
  &:active {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
    border-left: 4px solid #000;
  }
  h2.title {
    display: inline-block;
    padding-bottom: 0.4rem;
  }
}
</style>

<script>
import axios from "axios";
import { dateToFormatString } from "../lib/datefunctions.js";

export default {
  name: "NewsSingle",
  data: function() {
    return {
      posts: [],
      page: 0,
      loading: false,
      disabled: false
    };
  },

  mounted: function() {
    this.page = 1;
  },

  watch: {
    page() {
      const url =
      process.env.VUE_APP_API_ENDPOINT + `news/${this.$route.params.post_id}?_embed`;

      (async () => {
        try {
          const res = await axios.get(url);

          this.posts = this.posts.concat(res.data);
          //console.log(this.posts);
          //console.log("ok");
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.empty();
        }
      })();
    }
  },

  methods: {
    load() {
      this.loading = true;
      this.page++;
    },

    empty() {
      this.loading = false;
      this.disabled = true;
    },
    dateToFormatString(date, fmt, locale, pad) {
      return dateToFormatString(date, fmt, locale, pad);
    },
    wplink_to_url(link) {
      return "/" + link.replace(process.env.VUE_APP_WP_ROOT, "");
    }
  }
};
</script>
