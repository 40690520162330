import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/Home";
import Content from "@/components/Content";
import News from "@/components/News";
import NewsSingle from "@/components/NewsSingle";
import Books from "@/components/Books";
import BookSingle from "@/components/BookSingle";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { title: "ページタイトル", desc: "ディスクリプションを記述" },
    },
    {
      path: "/about",
      name: "about",
      component: Content,
    },
    {
      path: "/news",
      name: "news",
      component: News,
    },
    {
      path: "/news/:post_id",
      name: "news-single",
      component: NewsSingle,
    },
    {
      path: "/books",
      name: "books",
      component: Books,
    },
    {
      path: "/books/:post_id",
      name: "book-single",
      component: BookSingle,
    },
  ],
});
