<template>
  <div class="fullheight ml-top">
    <div class="bg-wrapper">
      <app-background></app-background>
    </div>
    <b-container class="main fullheight" fluid>
      <b-row class="fullheight">
        <b-col class="fullheight ml-border-right" md="3">
          <b-row class="height25">
            <b-col class="fullheight fullwidth ml-border-bottom">
              <router-link to="about" tag="div" class="link">
                <h2>About</h2>
                <div class="description">このプロジェクトについて</div>
              </router-link>
            </b-col>
          </b-row>
          <b-row class="height25">
            <b-col class="fullheight fullwidth ml-border-top ml-border-bottom">
              <router-link to="news" tag="div" class="link">
                <h2>News</h2>
                <div class="description">最新情報</div>
              </router-link>
            </b-col>
          </b-row>
          <b-row class="height50">
            <b-col class="fullheight fullwidth ml-border-top">
              <router-link to="books" tag="div" class="link">
                <h2>Books</h2>
                <div class="description">所蔵している本について</div>
              </router-link>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="fullheight ml-border-left ml-rightpane" md="9"></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
//import MainContents from "../components/partials/MainContents";
import Background from "../components/partials/Background";
export default {
  name: "MyHome",
  components: {
    //appMainContents: MainContents,
    appBackground: Background
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
ul.mainnav {
  padding: 0;
  li span {
    display: inline-block;
    background-color: #fff;
    color: #000;
    padding: 0.2rem 0.4rem;
    font-weight: 700;
  }
}

h2 {
  display: inline-block;
  //background-color: #fff;
  color: #fff;
  padding: 0.2rem 0.4rem;
  font-size: 4rem;
  font-weight: 700;
  margin-left: -15px;
  padding-left: 30px;
  padding-right: 15px;
  text-shadow: 0 0 4px #666;
}
.main {
  //border: 8px solid #fff;
  position: relative;
}
.ml-el,
.ml-lg,
.ml-md,
.ml-sm,
.ml-es {
  .fullheight {
    min-height: 100%;
    height: 100%;
  }
}
.height25 {
  min-height: 25%;
  height: 25%;
}
.height50 {
  min-height: 50%;
  height: 50%;
}

.ml-el,
.ml-lg,
.ml-md {
  .ml-border-right {
    border-right: 4px solid #fff;
  }
}
.ml-sm,
.ml-es {
  .ml-rightpane {
    display: none;
  }
  .ml-border-right {
    border-right: none;
  }
}
.ml-border-left {
  border-left: 4px solid #fff;
}
.ml-border-bottom {
  border-bottom: 4px solid #fff;
}
.ml-border-top {
  border-top: 4px solid #fff;
}

.fullwidth {
  padding: 0;
}

div.link {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  @media (pointer: fine) {
    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
      h2 {
        color: #000;
        text-shadow: none;
      }
      div.description {
        display: block;
      }
    }
  }
  div.description {
    display: none;
    padding: 0.4rem 15px;
    font-size: 1.6rem;
  }
}
</style>
