<template>
  <div class="fullheight ml-top">
    <b-container class="main fullheight" fluid>
      <b-row class="bg-white fullheight">
        <b-col class="fullheight ml-sidebar" md="3">
          <div class="ml-content-nav fixed ml-border-right">
            <h2>Books</h2>
            <!--<li>
              <span>
                <a href="#microlibrary">〔カテゴリー〕</a>
              </span>
            </li>-->
          </div>
        </b-col>
        <b-col md="9">
          <b-row>
            <b-col md="12 ml-content">
              <b-container>
                <transition-group class="columns is-gapless is-multiline" name="fade" tag="section">
                  <ml-post-list-item
                    v-for="post in posts"
                    v-bind:key="post.title.rendered"
                    v-bind="{ post: post, categories: categories }"
                  ></ml-post-list-item>
                </transition-group>
                <div style="width: 100%;max-width: 720px;">
                  <div v-show="loading">
                    <b-spinner label="Loading..."></b-spinner>
                  </div>
                  <b-button
                    v-show=" page < totalpages && !loading"
                    variant="dark"
                    class="float-right"
                    :class="[{'is-loading': loading,'is-desabled': disabled}]"
                    :disabled="disabled"
                    @click="load"
                  >つづきを読み込む</b-button>
                </div>
              </b-container>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
a {
  display: block;
  border-left: 4px solid #fff;
  padding-left: 10px;
  &:link,
  &:visited,
  &:active {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
    border-left: 4px solid #000;
  }
  h2.title {
    display: inline-block;
    padding-bottom: 0.4rem;
    border-bottom: 1px dashed #aaa;
  }
}
</style>

<script>
import axios from "axios";
import postListItem from "../components/partials/PostListItem";

export default {
  name: "MyBooks",
  components: {
    mlPostListItem: postListItem
  },
  data: function() {
    return {
      mode: "",
      posts: [],
      page: 0,
      totalpages: 0,
      categories: [],
      loading: true,
      disabled: false
    };
  },

  mounted: function() {
    this.page = 1;

    const category_qyery = process.env.VUE_APP_API_ENDPOINT + "book_categories";
    (async () => {
      try {
        var res = await axios.get(category_qyery);
        if (res.data.length !== 0) {
          this.categories = Array(res.data.length);
          for (let i = 0; i < res.data.length; i++) {
            this.categories[parseInt(res.data[i].id)] = res.data[i].name;
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  },

  watch: {
    page() {
      const url = process.env.VUE_APP_API_ENDPOINT + `book?page=${this.page}&_embed`;
      const auth = "Basic " + process.env.VUE_APP_APP_PASSWORD;

      (async () => {
        try {
          var res;
          if (this.$route.query.mode == "draft") {
            res = await axios.get(url + "&status=draft", {
              headers: {
                Authorization: auth,
                "Content-Type": "application/json"
              }
            });
          } else {
            res = await axios.get(url);
          }
          this.totalpages = res.headers["x-wp-totalpages"];
          this.posts = this.posts.concat(res.data);
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.empty();
        }
      })();
    }
  },

  methods: {
    load() {
      this.loading = true;
      this.page++;
    },

    empty() {
      this.loading = false;
      this.disabled = true;
    }
  }
};
</script>
