<template>
  <b-navbar
    toggleable="md"
    class="ml-header"
    v-bind:class="{ 'ml-header-border': $route.path !== '/' }"
  >
    <b-navbar-brand to="/" class="title">{{ title }}</b-navbar-brand>
    <b-nav-toggle target="nav_collapse"></b-nav-toggle>
    <b-collapse is-nav id="nav_collapse">
      <b-navbar-nav class="mr-auto">
        <b-nav-item class="nav_item" :class="{'active': isAboutActive}" to="/about">About</b-nav-item>
        <b-nav-item class="nav_item" :class="{'active': isNewsActive}" to="/news">News</b-nav-item>
        <b-nav-item class="nav_item" :class="{'active': isBooksActive}" to="/books">Books</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-nav-item
          class="nav_item"
          href="https://www.instagram.com/zo_sho_library/"
          target="_brank"
          title="instagram"
          alt="instagram"
        >
          <font-awesome-icon :icon="['fab', 'instagram']" size="lg"/>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "MyHome",
  data() {
    return {
      title: this.$siteTitle
    };
  },
  computed: {
    isAboutActive() {
      if (!this.$route.path.indexOf("/about")) {
        return true;
      } else {
        return false;
      }
    },
    isNewsActive() {
      if (!this.$route.path.indexOf("/news")) {
        return true;
      } else {
        return false;
      }
    },
    isBooksActive() {
      if (!this.$route.path.indexOf("/book")) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>



<style lang="scss" scoped>
.ml-header {
  height: 50px;
  background-color: #fff;
  font-size: 1.6rem;
  padding: 10px 0;
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
}
.ml-header-border {
  border-bottom: 1px solid #ccc;
}

.title {
  margin: 0 15px;
  font-size: 2rem;
  font-weight: 700;
}
.navbar-nav {
  background-color: #fff;
  padding: 0 15px;
  border-bottom: 1px solid #ccc;
}
.nav-item {
  &:not(.active) {
    font-weight: normal;
  }
  .active {
    font-weight: 700;
  }
}
.navbar-toggler {
  margin-right: 10px;
}

.ml-el,
.ml-lg,
.ml-md,
.ml-sm {
  .navbar-nav {
    border-bottom: none;
  }
  .nav-item {
    padding: 0;
  }
}
.ml-sm,
.ml-es {
  .nav-item {
    padding: 15px 0;
  }
  .navbar-nav {
    border-bottom: 1px solid #ccc;
  }
}
</style>