<template>
  <b-row>
    <b-col md="10 ml-content">
      <app-carousel></app-carousel>
      <a class="anchor" id="microlibrary"></a>
      <div class="postcontent" v-html="content"></div>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";
import Carousel from "./Carousel";

export default {
  name: "PageContent",
  components: {
    appCarousel: Carousel
  },
  data() {
    return {
      posts: [],
      page: 0,
      content: "",
      loading: false,
      disabled: false
    };
  },
  mounted: function() {
    this.page = 1;
  },
  watch: {
    page() {
      const url = process.env.VUE_APP_API_ENDPOINT + `pages?slug=about&_embed`;

      (async () => {
        try {
          const res = await axios.get(url);

          this.posts = this.posts.concat(res.data);
          this.content = this.posts[0].content.rendered;
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.empty();
        }
      })();
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.page++;
    },

    empty() {
      this.loading = false;
      this.disabled = true;
    }
  }
};
</script>

<style lang="scss">
.ml-content {
  //padding-top: 15px;
  //padding-bottom: 30px;
  font-size: 1.4rem;
  max-width: 960px;
  & > *:last-child {
    padding-bottom: 100px;
  }
}
.postcontent {
  h2 {
    font-size: 1.4rem;
    font-weight: 700;
  }
  h3 {
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 3rem;
  }
  p {
    font-weight: normal;
  }
}
.fullheight {
  min-height: 100%;
  height: 100%;
}
.bg-white {
  background-color: #fff;
}
.bg-opac {
  background-color: rgba(255, 255, 255, 0.9);
}

img.full {
  margin-top: 2rem;
  &:first-child {
    margin-top: 0;
  }
}

a.anchor {
  display: block;
  position: relative;
  top: -50px;
  visibility: hidden;
}
</style>