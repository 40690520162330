<template>
  <div class="fullheight ml-top">
    <b-container class="main fullheight" fluid>
      <b-row class="bg-white fullheight">
        <b-col class="ml-sidebar fullheight" md="3">
          <div class="ml-content-nav fixed ml-border-right">
            <h2>About</h2>
          </div>
          <!--<ul class="ml-content-nav fixed">
            <li>
              <span>
                <a href="#microlibrary">{{ this.$siteTitle }}</a>
              </span>
            </li>
            <li>
              <span>
                <a href="#organization">準備室</a>
              </span>
            </li>
            <li>
              <span>
                <a href="#contact">問い合わせ</a>
              </span>
            </li>
          </ul>-->
        </b-col>
        <b-col md="9">
          <app-main-contents></app-main-contents>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PageContent from "../components/partials/PageContent";
const TIMEOUT = 1;
export default {
  name: "ml-content",
  components: {
    appMainContents: PageContent
  },
  data() {
    return {
      bgimages: {
        backgroundImage: "url('static/img/20180722-DSC_3090_1200x801.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center bottom"
      }
    };
  },
  mounted() {
    // From testing, without a brief timeout, it won't work.
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
    }
  },
  methods: {
    scrollTo: function(hashtag) {
      setTimeout(() => {
        location.href = hashtag;
      }, TIMEOUT);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
