<template>
  <div id="app" class="page-wrapper" :class="mlMediaQuery">
        <app-header></app-header>
        <router-view></router-view>
  </div>
</template>

<script>
import Header from "./components/partials/Header";
//import Footer from "./components/partials/Footer";

export default {
  name: "app",
  components: {
    appHeader: Header,
    //appFooter: Footer
  },
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
      mlMediaQuery: ""
    };
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);
      window.addEventListener("resize", this.getWindowHeight);

      //Init
      this.getWindowWidth();
      this.getWindowHeight();
    });
  },

  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth >= 1200) {
        this.mlMediaQuery = "ml-el";
      } else if (this.windowWidth >= 992) {
        this.mlMediaQuery = "ml-lg";
      } else if (this.windowWidth >= 768) {
        this.mlMediaQuery = "ml-md";
      } else if (this.windowWidth >= 576) {
        this.mlMediaQuery = "ml-sm";
      } else {
        this.mlMediaQuery = "ml-es";
      }
    },

    getWindowHeight() {
      this.windowHeight = document.documentElement.clientHeight;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
    window.removeEventListener("resize", this.getWindowHeight);
  }
};
</script>

<style lang="scss">
html {
  font-size: 62.5%;
}
html,
body {
  overscroll-behavior-y: none;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Helvetica, YuGothic, "游ゴシック", "ヒラギノ角ゴ ProN W3",
    "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
  text-align: justify;
}
body {
  font-size: 1.4rem;
}
#app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}
.ml-top {
  padding-top: 50px;
}
.page-content-wrapper {
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
}
a:link,
a:visited,
a:active,
a:hover {
  color: #000;
}
/*
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}*/
</style>
