<template>
  <b-carousel
    id="carousel1"
    style="text-shadow: 1px 1px 2px #333;"
    controls
    background="#ababab"
    :interval="6000"
    img-width="1024"
    img-height="480"
    v-model="slide"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
  >
    <b-carousel-slide img-src="/img_2024/20240924-DSC_7028_1200x801.jpg"></b-carousel-slide>
    <b-carousel-slide img-src="/img_2024/20240924-DSC_7020_1200x801.jpg"></b-carousel-slide>
    <b-carousel-slide img-src="/img_2024/20240924-DSC_6968_1200x801.jpg"></b-carousel-slide>
    <b-carousel-slide img-src="/img_2024/20240924-DSC_6984_1200x801.jpg"></b-carousel-slide>
    <b-carousel-slide img-src="/img_2024/20240924-DSC_6981_1200x801.jpg"></b-carousel-slide>
    <b-carousel-slide img-src="/img/20180722-DSC_3090-2_1200x801.jpg"></b-carousel-slide>
    <b-carousel-slide img-src="/img/20180722-DSC_3020_1200x801.jpg"></b-carousel-slide>
    <b-carousel-slide img-src="/img/20180722-DSC_2987_1200x801.jpg"></b-carousel-slide>
    </b-carousel>
</template>

<script>
export default {
  name: "MyCarousel",
  data() {
    return {
      slide: 0,
      sliding: null
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    }
  }
};
</script>

<style lang="scss">
</style>