<template>
  <div>
    <b-card
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :class="{ 'shadow-sm': hover }"
      no-body
      class="overflow-hidden ml-card-row"
      style="max-width: 720px;"
    >
      <b-link
        class="stretched-link text-decoration-none"
        :to="{ name: singlelink, params: { post_id: post.id }}"
      >
        <b-row no-gutters>
          <b-col md="3">
            <b-card-img :src="imageUrl" class="rounded-0"></b-card-img>
          </b-col>
          <b-col md="9">
            <b-card>
              <div class="card-body">
                <h4 class="card-title">{{ post.title.rendered }}</h4>
                <p>
                  <small class="text-muted">{{ parseDate(Date.parse(post.date)) }}</small>
                </p>
                <span class="ml-post-cat badge badge-secondary">{{ post_category_str }}</span>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-link>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import { dateToFormatString } from "../../lib/datefunctions.js";

export default {
  name: "PostListItem",
  props: ["post", "categories"],
  data: function() {
    return {
      hover: false,
      imageUrl: "",
      post_category_str: "",
      singlelink: ""
    };
  },

  mounted() {
    var placeHolder =
      "https://zo-sho.org/wp/wp-content/uploads/2019/07/190703-300x300.jpg";
    if (this.post._embedded && this.post._embedded["wp:featuredmedia"]) {
      // featured image
      this.imageUrl = this.post._embedded["wp:featuredmedia"][0][
        "media_details"
      ]["sizes"]["medium"]["source_url"];
    } else {
      (async () => {
        try {
          var mediaQuery =
          process.env.VUE_APP_API_ENDPOINT + "media?parent=" + this.post.id;
          let res = await axios.get(mediaQuery);
          if (Object.keys(res.data).length != 0) {
            this.imageUrl = res.data[0].media_details.sizes.medium.source_url;
          } else {
            this.imageUrl = placeHolder;
          }
        } catch (error) {
          console.log(error);
          this.imageUrl = placeHolder;
        }
      })();
    }
    var temp_categories;
    if (this.post.type == "book") {
      this.singlelink = "book-single";
      temp_categories = this.post.book_categories;
    } else if (this.post.type == "news") {
      this.singlelink = "news-single";
      temp_categories = this.post.categories;
    }
    if (temp_categories.length !== 0) {
      var post_categories = Array(temp_categories.length);
      for (let i = 0; i < temp_categories.length; i++) {
        post_categories[i] = this.categories[temp_categories[i]];
      }
      this.post_category_str = post_categories.join(", ");
    }
  },

  methods: {
    dateToFormatString(date, fmt, locale, pad) {
      return dateToFormatString(date, fmt, locale, pad);
    },
    wplink_to_url(link) {
      return "/" + link.replace(process.env.VUE_APP_WP_ROOT, "");
    },
    parseDate(date) {
      var dateObj = new Date(date);
      //return dateObj.toLocaleDateString("ja-JP");
      return this.dateFormat(dateObj);
    },
    dateFormat(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      var d = date.getDate();
      var w = date.getDay();
      var wNames = ["日", "月", "火", "水", "木", "金", "土"];
      return y + "年" + m + "月" + d + "日 (" + wNames[w] + ")";
    }
    //titleFormat(str) {
    //  return str.replace(/(News_)|(Event_)|(Report_)/gi, "");
    //}
  }
};
</script>

<style lang="scss">
.card-img {
  width: 100%;
  height: 100%;
  max-height: 40vw;
  object-fit: cover;
}
.card-title {
  line-height: 1.6;
  font-size: 1.8rem;
}
.ml-card-row {
  margin-bottom: 20px;
  .card {
    border: none;
  }
}
.ml-post-cat {
  letter-spacing: 0.05rem;
}
</style>